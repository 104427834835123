/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Datetime from 'vux/src/components/datetime';
import PopupPicker from 'vux/src/components/popup-picker';
import XButton from 'vux/src/components/x-button';
import XTextarea from 'vux/src/components/x-textarea';
import UploadImageRequired from '@/components/UploadImage/UploadImageRequired';
import { Dialog, Toast } from 'vant';
import atitle from '@/components/Approve/atitle'; // -----审批节点

import FixedNode from '@/components/Approve/FixedNode';
import AssignNode from '@/components/Approve/AssignNode';
import OfficersNode from '@/components/Approve/OfficersNode';
import NoAssiginNode from '@/components/Approve/NoAssiginNode';
import copyOffNode from '@/components/Approve/copyOffNode';
import copyNoAssiginNode from '@/components/Approve/copyNoAssiginNode';
import copyFixedNode from '@/components/Approve/copyFixedNode';
export default {
  name: 'LeaveApply',
  components: {
    PopupPicker: PopupPicker,
    Datetime: Datetime,
    XTextarea: XTextarea,
    XButton: XButton,
    UploadImageRequired: UploadImageRequired,
    atitle: atitle,
    FixedNode: FixedNode,
    AssignNode: AssignNode,
    OfficersNode: OfficersNode,
    NoAssiginNode: NoAssiginNode,
    copyOffNode: copyOffNode,
    copyNoAssiginNode: copyNoAssiginNode,
    copyFixedNode: copyFixedNode
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.$utils.Store.removeItem('kssj');
    this.$utils.Store.removeItem('jssj');
    this.$utils.Store.removeItem('leaveLength');
    this.$utils.Store.removeItem('reason');
    next();
  },
  data: function data() {
    return {
      dian: false,
      mi: '',
      ha: false,
      nav: 0,
      xiaci: false,
      //又一次下一次
      navshow: false,
      //新手引导
      actions: this.$api.Base.dev + '/management/insertPhoto',
      //上传图片接口
      leaveList: [{
        value: '0',
        text: '事假' // index: 0,

      }],
      leaveList01: [{
        value: '0',
        text: '事假01' // index: 0,

      }, {
        value: '1',
        text: '事假02' // index: 0,

      }],
      leaveType: [],
      //请假类型
      startDate: '',
      //开始日期
      endDate: '',
      //截止日期
      kssj: this.$utils.Store.getItem('kssj') ? this.$utils.Store.getItem('kssj') : '',
      //开始时间
      jssj: this.$utils.Store.getItem('jssj') ? this.$utils.Store.getItem('jssj') : '',
      //结束时间
      minuteList: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
      //定义分钟列表
      leaveLength: null,
      //请假时长
      inputParam: [{
        name: 'att_id',
        value: 0
      }, {
        name: 'base64String',
        value: ''
      }, {
        name: 'typeId',
        value: -1
      }],
      imgList: [],
      //图片列表数组
      imgPath: [],
      //图片地址
      limitNum: 5,
      //最多上传图片张数
      bh: '00101',
      //假类编号
      reason: this.$utils.Store.getItem('reason') ? this.$utils.Store.getItem('reason') : '',
      //请假事由
      depSerial: this.$utils.Store.getItem('orgId'),
      //企业ID
      depName: this.$utils.Store.getItem('orgName'),
      //企业名称
      imgIndex: 0,
      //图片数组下标
      choose: this.$utils.Store.getItem('choose') ? this.$utils.Store.getItem('choose') : 0,
      //添加人员类型   1：审批人  2：抄送人  3：同行人
      infoChecked: [],
      //
      approve: [],
      //要提交的审批节点列表
      allUserList: [],
      //获取过来的审批节点
      process: 0,
      //0简单审批 1条件审批
      userSerial: this.$utils.Store.getItem('userSerial'),
      //人员编号
      approveConditionId: '',
      approveSettingId: '',
      cc: {},
      //抄送人
      haveCC: false,
      //是否开启了抄送
      showLevel: '',
      //展示级别（1：从审核级别1开始展示 2：从审核级别2开始）
      sbmitDis: true,
      //是否可以点击
      minTime: 0,
      //最小请假时长
      minTimeUnit: false,
      //最小请假时长单位
      isAttachment: 0,
      //附件是否必传  0：不必，1：必传
      isReason: 0,
      //请假理由是否必填  0：不必，1：必传
      isChoose: '',
      //动态class变量
      fujianChoose: '',
      //动态附件class变量
      judgeReason: true,
      approveStatues: false,
      //控制提交状态isDisabled
      chaosongzhuangtai: false,
      //控制提交状态isDisabled
      icon: 'icon-jiahao' //icon加号

    };
  },
  watch: {
    nav: function nav(val) {
      if (val == 1) {
        this.ha = true;
        this.navshow = true;
        this.noviceGuidances();
      }
    },
    jssj: function jssj() {
      var _this = this;

      this.getLeaveLength();
      this.leaveList.some(function (item) {
        if (item.value == _this.bh && item.process == 1) {
          _this.changeget();

          return true;
        }
      });
    },
    kssj: function kssj() {
      var _this2 = this;

      this.getLeaveLength();
      this.leaveList.some(function (item) {
        if (item.value == _this2.bh && item.process == 1) {
          _this2.changeget();

          return true;
        }
      });
    },
    leaveType: {
      handler: function handler() {
        //重置校验条件
        //切换请假类型
        for (var i = 0; i < this.leaveList.length; i++) {
          if (this.leaveList[i].value == this.bh) {
            // this.minTime=this.leaveList[i].qjMinValue
            this.minTimeUnit = this.leaveList[i].qjUnit;
            this.isReason = this.leaveList[i].isReason;
            this.approveStatues = false;
            this.chaosongzhuangtai = false;
            this.isReason == 0 ? this.isChoose = true : this.isChoose = ''; //请假理由是否必填控制

            this.isReason == 0 ? this.reason = '' : this.reason = '';
            this.isReason == 1 ? this.judgeReason = false : this.judgeReason = true;
            this.isReason == 1 ? this.reason = '' : this.reason = '';
            this.isAttachment = this.leaveList[i].isAttachment;

            if (this.minTimeUnit == 0) {
              // toFixed(2)避免出现1/3 出现多为小数0.33333333333
              this.minTime = (this.leaveList[i].qjMinValue / 60).toFixed(2);
            } else if (this.minTimeUnit == 1) {
              // 小时
              this.minTime = this.leaveList[i].qjMinValue;
            } else {
              // 天
              this.minTime = this.leaveList[i].qjMinValue * 60;
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
    leaveLength: function leaveLength() {}
  },
  computed: {
    isDisabled: function isDisabled() {
      //this.isAttachment == 0 附件不必上传
      if (this.isAttachment == 0) {
        if (this.sbmitDis && this.leaveType.length > 0 && this.kssj !== '' && this.jssj !== '' && this.judgeReason && this.leaveLength !== '' && this.leaveLength !== null) {
          /**/
          if (this.$params.leaveLength === '1') {
            return this.leaveLength === '';
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        if (this.sbmitDis && this.leaveType.length > 0 && this.kssj !== '' && this.jssj !== '' && this.judgeReason && this.imgList != '' && this.leaveLength !== null) {
          /**/
          if (this.$params.leaveLength === '1') {
            return this.leaveLength === '';
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },
    isRequireReason: function isRequireReason() {
      if (this.isReason == 1) {
        if (this.reason) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  mounted: function mounted() {
    this.nav = this.$utils.Store.getItem('nav');
  },
  methods: {
    /* 2021/11/15 优化增加删除不影响业务*/
    getShenpiRenshu: function getShenpiRenshu(aa) {
      /* 审批人数关联提交状态子组建传值 */
      if (aa > 0) {
        this.approveStatues = true;
      } else if (aa == 0) {
        this.approveStatues = false;
      }
    },

    /* 2021/11/15 优化增加删除不影响业务*/
    gechaosongrenshu: function gechaosongrenshu(aa) {
      /*抄送人数关联提交状态子组建传值 */
      if (aa > 0) {
        this.chaosongzhuangtai = true;
      } else if (aa == 0) {
        this.chaosongzhuangtai = false;
      }
    },
    //新手引导(多级审批)
    noviceGuidances: function noviceGuidances(val) {
      if (!this.ha) {
        return;
      }

      if (this.dian) {
        this.navshow = true;
        this.xiaci = true;
        var bootModule = document.getElementById(val);
        var bndf = document.getElementById('bndf');
        var navBoxTop = document.getElementsByClassName('navBoxTopw'); //获取小圆圈以及竖线 外边的dom对象

        var Itemwidth = bootModule.offsetWidth;
        bootModule.style.background = 'transparent'; //高亮背景颜色

        bootModule.style.zIndex = '502';
        bootModule.style.border = '1px dashed #fff';
        bootModule.style.padding = '0.04rem';
        bootModule.style.borderRadius = ' 0.06rem';
        bndf.style.top = -parseInt(40) + 'px';
        navBoxTop[0].style.left = parseInt(Itemwidth / 4, 10) + 'px';
      } else {
        var _bootModule = document.getElementById('multistageApproval');

        var _Itemwidth = _bootModule.offsetWidth;

        var Itemtop = _bootModule.getBoundingClientRect().bottom; //获取dom对象底边与顶部距离


        var GuideBox = document.getElementById('IndexGuideBox'); //获取蒙版dom对象

        var _navBoxTop = document.getElementsByClassName('navBoxTop'); //获取小圆圈以及竖线 外边的dom对象


        _bootModule.style.background = 'transparent'; //高亮背景颜色

        _bootModule.style.zIndex = '502';
        _bootModule.style.border = '1px dashed #fff';
        _bootModule.style.padding = '0.04rem';
        _bootModule.style.borderRadius = ' 0.06rem';
        GuideBox.style.position = 'absolute';
        GuideBox.style.top = parseInt(Itemtop) + parseInt(10) + 'px';
        _navBoxTop[0].style.left = parseInt(_Itemwidth / 2, 10) + 'px';
      }
    },
    cancelNav: function cancelNav() {
      this.navshow = false;
      this.ha = false;
      this.mi = false;
      this.$utils.Store.setItem('firstLogin', 0);
      this.$utils.Store.setItem('nav', 0);
      this.$utils.Store.setItem('navs', 0);
      this.firstLogin = 0;
    },
    nextNav: function nextNav() {
      if (this.xiaci) {
        this.$utils.Store.setItem('nav', 1);
        this.$utils.Store.setItem('navs', 1);
        this.$router.push({
          path: '/multistageApproval'
        });
      }

      this.$utils.Store.setItem('nav', 1);
      this.navshow = false;
      this.dian = true;
      var bootModule = document.getElementById('multistageApproval'); //  制作边框

      bootModule.style.background = 'white'; //高亮背景颜色

      bootModule.style.zIndex = 'auto'; //蒙版是501  高出蒙版就可以显示蒙版上层

      bootModule.style.border = 'none'; //添加边框

      bootModule.style.padding = '0rem'; //边框与内容有个内边距

      bootModule.style.borderRadius = '0rem'; //边框圆角弧度

      this.noviceGuidances('NodeTe');
    },
    // 设置探亲假
    setHomeTime: function setHomeTime() {
      if (this.$params.homeLeaveTime == 1 && this.bh == '00198' && this.kssj != '') {
        var date0 = this.kssj;
        date0 = date0.replace(/-/g, '/');
        var now = new Date(date0);
        var date = new Date(now.getTime() + 9 * 24 * 3600 * 1000);
        var endDate = this.$utils.DateUtils.getYYYYMMDDHHmm(date);
        this.jssj = endDate;
        this.endDate = this.jssj.substring(0, 10);
      }
    },
    // 获取服务器时间---今日日期
    getSysDate: function getSysDate() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.kssj = data.sysDate.substring(0, 16);
          _this3.jssj = _this3.dateEnd(_this3.kssj);
          _this3.startDate = data.sysDate.substring(0, 10);
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    // 获取请假类型数组
    getLeaveList: function getLeaveList() {
      var _this4 = this;

      this.$utils.Tools.loadShow();
      this.$api.Leave.selectLeave({
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          data.forEach(function (item, i) {
            //循环data数组，若存在事假，将事假置于数组第一条，其余依次写入，将默认请假类型设置为事假
            if (item.mc == '事假') {
              // "bh"：编号  "process":"0:简单审批 1:条件审批 6职级审批",
              _this4.leaveList[0].value = item.bh;
              _this4.leaveList[0].name = item.mc;
              _this4.leaveList[0].process = item.process;
              _this4.leaveType = [item.bh];
              _this4.bh = item.bh;
              _this4.leaveList[0].qjMinValue = item.qjMinValue;
              _this4.leaveList[0].qjUnit = item.qjUnit;
              _this4.leaveList[0].isAttachment = item.isAttachment;
              _this4.leaveList[0].isReason = item.isReason;
              _this4.leaveList[0].index = 0;
            } else {
              _this4.leaveList.push({
                name: item.mc,
                value: item.bh,
                index: i,
                process: item.process,
                qjMinValue: item.qjMinValue,
                qjUnit: item.qjUnit,
                isReason: item.isReason,
                isAttachment: item.isAttachment
              });
            }
          });
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    // 获取假类编号
    changeLeave: function changeLeave(val) {
      this.leaveType = val;
      this.bh = val[0];
      this.$utils.Store.setItem('bh', val[0]);
      this.approve = [];
      this.showLevel = '';
      this.changeget(); //  只有满足条件才启动以下探亲假方法

      this.setHomeTime();
    },
    // 判断什么方式获取审批节点
    changeget: function changeget() {
      var _this5 = this;

      this.allUserList = []; // some() 方法用于检测数组中的元素是否满足指定条件
      // 如果有一个元素满足条件，则表达式返回true , 剩余的元素不会再执行检测。 如果没有满足条件的元素，则返回false。

      this.leaveList.some(function (item) {
        // process=0：简单审批
        if (_this5.bh == item.value && item.process == 0) {
          // 获取可审批的节点
          _this5.getNodeList();

          return true;
        } else if (_this5.bh == item.value && item.process == 1) {
          // process=0：条件审批
          //已存在开始时间和结束时间（默认当时时间）
          if (_this5.startDate && _this5.endDate) {
            _this5.getNodeList();

            return true;
          }
        }
      });
    },
    // 获取请假时长
    getLeaveLength: function getLeaveLength() {
      var _this6 = this;

      this.$api.Leave.getLeaveLength({
        userSerial: this.userSerial,
        kssj: this.kssj,
        jssj: this.jssj,
        bh: this.bh
      }).then(function (res) {
        var code = res.code,
            msg = res.msg,
            data = res.data;

        if (+code !== _this6.$code.success) {
          _this6.$utils.Tools.toastShow(msg || '获取请假时长失败');
        } else {
          _this6.leaveLength = data;
        }
      });
    },
    // 获取可审批的节点
    getNodeList: function getNodeList() {
      var _this7 = this;

      this.$utils.Tools.loadShow();
      this.approve = [];
      this.cc = {};
      this.haveCC = false;
      this.allUserList = [];
      var params = {
        approveCode: '0',
        //请假
        subCode: this.bh,
        //编号
        startTime: this.kssj,
        //开始时间
        endTime: this.jssj,
        //结束时间
        userSerial: this.userSerial //人员序列

      }; // 查询可选审批人列表

      this.$api.Appr.getApplicants(params).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (code == '602') {
          _this7.$utils.Tools.loadHide();

          Dialog({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          });
          return;
        }

        if (+code !== _this7.$code.success) {
          _this7.$utils.Tools.loadHide();

          Toast(msg);
        } else {
          _this7.$utils.Tools.loadHide(); // 展示级别（1：从审核级别1开始展示 2：从审核级别2开始）


          _this7.showLevel = data.showLevel; // 如果从审核级别1开始展示

          if (data.showLevel == '1') {
            // 将审批等级列表存入allUserList
            _this7.allUserList = data.approve;
          } else {
            // 循环审批等级列表
            for (var i = 0; i < data.approve.length; i++) {
              // 如果当前数据的 级别/层级小于展示级别
              if (data.approve[i].level < data.showLevel) {
                (function () {
                  var userItem = {};
                  var user = [];
                  data.approve[i].user.some(function (sub) {
                    user.push(sub.userSerial);
                  });
                  userItem.level = data.approve[i].level;
                  userItem.way = data.approve[i].levelWay;
                  userItem.levelId = data.approve[i].levelId;
                  userItem.userSerial = user;

                  _this7.approve.push(userItem);
                })();
              } else {
                _this7.allUserList.push(data.approve[i]);
              }
            }
          } //data.ccSet   cc:抄送人信息   copySwitch:开关 1开 0关 后台查询限制了，前端可不做处理   copyType:抄送类型：1不指定 2固定抄送人 4发起人部门领导 5职务  copyWay:抄送方式：0固定抄送 1自选抄送


          if (data.ccSet) {
            _this7.haveCC = true;
            _this7.allUserList[_this7.allUserList.length] = data.ccSet; //抄送
          }
          /* 为啥要这么写呢 因为抄送人是可以配置的会议固定抄送人 */


          if (data.ccSet && data.ccSet.copyWay == '0') {
            _this7.chaosongzhuangtai = true;
          }

          _this7.approveConditionId = data.approveConditionId;
          _this7.approveSettingId = data.approveSettingId;
          _this7.process = data.process;
        }
      }).catch(function () {
        _this7.$utils.Tools.httpError();
      });
    },
    //获取当前的最小请假时长
    getmintime: function getmintime() {},
    // 开始时间后一个小时
    dateEnd: function dateEnd(val) {
      var atime = val.replace(/-/g, '/');
      var now = new Date(atime);
      var time = now.getTime() + 1000 * 60 * 60;
      var dd = new Date(time);
      var seperator1 = '-';
      var seperator2 = ':'; //   dd.setDate(dd.getDate() + AddDayCount) //获取AddDayCount天后的日期

      var y = dd.getFullYear();
      var m = dd.getMonth() + 1; //获取当前月份的日期

      var d = dd.getDate();
      var h = dd.getHours();
      var mm = dd.getMinutes();
      m = m > 9 ? m : '0' + m; //格式化时间

      d = d > 9 ? d : '0' + d;
      h = h > 9 ? h : '0' + h;
      mm = mm > 9 ? mm : '0' + mm;
      return y + seperator1 + m + seperator1 + d + ' ' + h + seperator2 + mm;
    },
    //    获取请假开始时间
    changeStartTime: function changeStartTime(val) {
      this.kssj = val;
      this.$utils.Store.setItem('kssj', val);
      this.startDate = val.substring(0, 10); //  只有满足条件才启动以下探亲假方法

      this.setHomeTime();
    },
    //    获取请假结束时间
    changeEndTime: function changeEndTime(val) {
      this.jssj = val;
      this.$utils.Store.setItem('jssj', val);
      this.endDate = val.substring(0, 10);
    },
    //    获取请假事由
    getReason: function getReason(val) {
      this.reason = val;
      this.$utils.Store.setItem('reason', val);

      if (this.reason != '' && this.isReason == 1) {
        this.judgeReason = true;
      } else if (this.reason == '' && this.isReason == 1) {
        this.judgeReason = false;
      }
    },
    //    遍历数组是否少
    ergArray: function ergArray(list) {
      if (!list) {
        return true;
      }

      var uindex = list.findIndex(function (item) {
        return !item.userSerial[0];
      });
      return uindex != -1;
    },
    getInervalHour: function getInervalHour(startDate, endDate) {
      var ms = endDate.getTime() - startDate.getTime();
      if (ms < 0) return 0;
      return Math.floor(ms / 1000 / 60 / 60);
    },
    //提交前的准备
    inspectInfo: function inspectInfo() {
      //将请假时间加上毫秒 固定为00 方便转换为时间戳 用于时间的判断
      var start_time = this.kssj + ':00';
      var end_time = this.jssj + ':00';
      var starttime = new Date(start_time);
      var endtime = new Date(end_time);
      var difftime = (endtime - starttime) / 1000; //计算时间差,并把毫秒转换成秒

      var days = parseInt(difftime / 86400); // 天  24*60*60*1000

      var hours = parseInt(difftime / 3600) - 24 * days; // 小时 60*60 总小时数-过去的小时数=现在的小时数

      var minutes = parseInt(difftime % 3600 / 60); // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数

      var totalTime = days * 24 + hours + minutes / 60; //小时数 天数*25+小时+分钟/60
      // return false

      var reg = /(^[0-9]+$)|(^[0-9]+[.]{1}[0-9]{0,1}$)/;
      if (this.$params.Txtlength == '1' && this.reason.length < 20) return this.$utils.Tools.toastShow('请假事由字数少于20个字！！');

      if (this.kssj > this.jssj) {
        Toast('开始时间不能大于结束时间');
        return;
      } else if (this.kssj == this.jssj) {
        Toast('开始时间不能与结束时间相同');
        return;
      } else if (this.leaveLength == '' || this.leaveLength == null) {
        Toast('未填写请假时长');
      } else if (this.leaveLength && !reg.test(this.leaveLength)) {
        Toast('必须为数字格式，且时长小数位最多1位');
        return;
      } else if (this.MinLeaveTime > this.leaveLength) {
        Toast('请假时长不能小于最短请假时长');
      } else if (this.leaveLength > totalTime) {
        Toast('请假时长不能大于实际请假时长');
      } else if (this.minTime > this.leaveLength) {
        Toast('请假时长不能小于最短请假时长' + this.minTime + '小时');
      } else if (this.ergArray(this.approve)) {
        Toast('未选择审批人');
        return;
      } else if (this.haveCC && !this.cc.userSerial[0]) {
        Toast('未选择抄送人');
        return;
      } else {
        this.imgPath = []; // 循环附件

        var _iterator = _createForOfIteratorHelper(this.imgList),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var imgs = _step.value;
            this.imgPath.push(imgs.serverId);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        var imgPath = JSON.stringify(this.imgPath).replace(/\[|]|"/g, '');

        if (this.isAttachment == 1) {
          if (this.imgPath == '') {
            this.$utils.Tools.toastShow('附件不能为空');
            return;
          }
        } // 业务实体


        var businessEntity = {
          bh: this.bh,
          //请假类型编号
          bz: this.reason,
          //请假事由
          imgPath: imgPath,
          //附件（图片地址）
          kssj: this.kssj,
          //开始时间
          jssj: this.jssj,
          //结束时间
          leaveLength: this.leaveLength === null ? 0 : this.leaveLength,
          //请假时长：若请假时长为空则默认为0
          processId: '',
          qjlx: 1,
          //"0:PC端 1:移动端"
          userSerial: this.$utils.Store.getItem('userSerial') //人员序号

        }; //参数

        var params = {
          businessEntity: businessEntity,
          //业务实体
          approveCode: 0,
          //申请大类编号 0请假 1出差 2出 3加班 4调班 5补出勤 6外勤 8销假
          approveSettingId: this.approveSettingId,
          //设置id
          approveConditionId: this.approveConditionId,
          //条件id
          approveCodeSub: this.bh,
          //子类型码 有则必填
          petitioner: this.$utils.Store.getItem('userSerial'),
          //申请人序号
          cc: [this.cc],
          //抄送
          approve: this.approve,
          //审批
          showLevel: this.showLevel //审核级别

        }; // return
        //提交

        this.submitClick(params);
      }
    },
    // 提交请假申请
    submitClick: function submitClick(params) {
      var _this8 = this;

      this.sbmitDis = false;
      this.$utils.Tools.loadShow();
      this.$api.Appr.apply(params).then(function (response) {
        _this8.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;
        _this8.sbmitDis = true; // 返回状态码为625或626 弹出提示框

        if (code == '625' || code == '626') {
          _this8.$utils.Tools.loadHide();

          Dialog.alert({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          }).then(function () {
            // 点击弹框确定按钮 刷新页面
            _this8.$router.go(0);
          });
          return;
        }

        if (+code !== _this8.$code.success) {
          Toast(msg);
        } else {
          Toast('提交成功'); // 提交成功 返回上一页

          _this8.$router.go(-1);
        }
      }).catch(function () {
        _this8.$utils.Tools.httpError();
      });
    },
    // 当前提交审批节点中是否有对应的节点
    inspectObj: function inspectObj(sub) {
      var index = 0;
      var flag = this.approve.some(function (items, i) {
        if (items.level == sub.level) {
          index = i;
          return true;
        }
      });
      return {
        index: index,
        flag: flag
      };
    },
    //获取子集的节点对象
    getSubUserObj: function getSubUserObj(appr) {
      if (appr.cc && appr.cc == 1) {
        this.cc = appr;
        return;
      } // 如果approve为空 将appr拼接到approve后面


      if (this.approve.length == 0) {
        this.approve.push(appr);
      } else {
        var insUser = this.inspectObj(appr);

        if (insUser.flag) {
          //如果有重复的
          this.approve[insUser.index] = appr;
        } else {
          //没有重复的
          this.approve.push(appr);
        }
      }
    }
  },
  created: function created() {
    this.getSysDate(); // 获取请假类型数组

    this.getLeaveList();

    if (this.startDate && this.endDate) {
      // 判断什么方式获取审批节点
      this.changeget();
    }
  },
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('oldVal');
  }
};