/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.splice.js";
var mixin = {
  data: function data() {
    return {
      // 展示的审批人
      userLists: [],
      // 是否展示更多按钮
      moreFlag: false,
      // 是否打开更多的pop
      showMoreBtn: false
    };
  },
  methods: {
    // 处理展示的用户列表
    handelShowUserList: function handelShowUserList(newVal) {
      if (!newVal) {
        return;
      }

      if (newVal.length > 3) {
        this.moreFlag = true;
        this.userLists = _toConsumableArray(newVal).splice(0, 2);
      } else if (newVal.length > 0) {
        this.moreFlag = false;
        this.showMoreBtn = false;
        this.userLists = _toConsumableArray(newVal);
      }
    }
  }
};
export default mixin;