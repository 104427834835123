/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Popup from 'vux/src/components/popup';
import TransferDom from 'vux/src/directives/transfer-dom';
import { Dialog, Toast } from 'vant'; // ---组织结构树-----

import OrgTree from '@/components/OrgTree/OrgTree';
import approvalMixin from "@/mixins/approval/approvalNode";
export default {
  mixins: [approvalMixin],
  components: {
    Popup: Popup,
    OrgTree: OrgTree
  },
  directives: {
    TransferDom: TransferDom
  },
  data: function data() {
    return {
      wayName: ['', '会签', '或签', '依次审批'],
      addFlag: true,
      //加号的显示
      allLists: [],
      //可添加的人
      showAllBtn: false,
      //
      msgNum: '',
      //几个人
      referAll: {},
      //提交给父级的所有人--对象
      depSerial: this.$utils.Store.getItem('orgId'),
      //企业ID
      depName: this.$utils.Store.getItem('orgName'),
      //企业名称
      // approverString: '', //审批人序号--字符串
      choose: this.$utils.Store.getItem('choose') ? this.$utils.Store.getItem('choose') : 0,
      //添加人员类型   1：审批人  2：抄送人  3：同行人
      showFlag: false,
      //是否显示组织架构弹窗
      selectType: 0,
      //组织架构选择类型
      infoChecked: [],
      //
      approverChecked: [],
      //审批人选中tItem('oldVal') ? JSON.parse(this.$utils.Store.getItem('oldVal')) : [],    //审批人
      moreLists: []
    };
  },
  props: {
    itemUser: Object
  },
  watch: {
    itemUser: {
      //深度监听父组件的list
      handler: function handler() {
        this.addFlag = true;
        this.countUser();
      },
      deep: true,
      immediate: true
    },
    msgNum: {
      handler: function handler(newVal) {
        this.$emit('getShenpiRenshu', newVal);

        if (newVal < 10) {
          this.addFlag = true;
        } else if (newVal >= 10) {
          this.addFlag = false;
        }
      },
      deep: true
    },
    moreLists: {
      //添加的列表
      handler: function handler(newVal) {
        this.handelShowUserList(newVal);
        this.countUser();
      },
      deep: true
    }
  },
  methods: {
    clearUserClick: function clearUserClick(i) {
      var _this = this;

      var clearUser = this.userLists.splice(i, 1);
      this.moreLists.some(function (sub, ix) {
        if (clearUser[0].userSerial == sub.userSerial) {
          _this.moreLists.splice(ix, 1);

          return true;
        }
      }); //  this.allLists.push(clearUser[0]);

      if (this.userLists.length == 0 && this.moreLists.length > 1) {
        this.$set(this.userLists, 0, this.moreLists[0]);
      }
    },
    moreClearClick: function moreClearClick(i) {
      this.moreLists.splice(i, 1);
    },
    // 删减人员的增减的效果---统一的  lwt  07-03
    countUser: function countUser() {
      // this.msgNum = this.userLists.length
      this.msgNum = this.moreLists.length;

      var lists = _toConsumableArray(this.moreLists);

      var ulists = []; // let items = { userSerial: '' }
      // if (this.userLists.length != 0) {
      //    items= this.userLists[0]
      // }

      lists.forEach(function (item) {
        ulists.push(item.userSerial);
      });
      this.referAll = {
        level: this.itemUser.level,
        way: this.itemUser.levelWay,
        // userSerial: [items.userSerial],
        userSerial: ulists,
        levelId: this.itemUser.levelId
      };
      this.$emit('getSubUserObj', this.referAll);
    },
    // 查看名字---lwt 07-03
    moreName: function moreName(name) {
      Dialog({
        message: name,
        width: '70%',
        closeOnPopstate: true
      });
    },
    // -------以下是组织结构树的内容
    selectUsers: function selectUsers(val) {
      var _this$moreLists;

      var us = this.$utils.Store.getItem('userSerial');

      if (val[0].userSerial == us) {
        Dialog({
          title: '提示',
          message: '审批人不可选本人',
          width: '70%',
          closeOnPopstate: true
        });
        return;
      } //  this.userLists = val
      //  this.countUser()


      var useflag = this.moreLists.some(function (item) {
        return item.userSerial == val[0].userSerial;
      });

      if (useflag) {
        Toast('审批人重复');
        return;
      }

      (_this$moreLists = this.moreLists).push.apply(_this$moreLists, _toConsumableArray(val));
    },
    chooseApprover: function chooseApprover() {
      this.infoChecked = [];
      this.selectType = 1;
      this.showFlag = true;
    }
  }
};