/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Popup from 'vux/src/components/popup';
import TransferDom from 'vux/src/directives/transfer-dom';
import { Dialog } from 'vant';
import approvalMixin from "@/mixins/approval/approvalNode";
export default {
  mixins: [approvalMixin],
  components: {
    Popup: Popup
  },
  directives: {
    TransferDom: TransferDom
  },
  data: function data() {
    return {
      wayName: ['', '会签', '或签'],
      moreLists: [],
      //更多的展示页面的
      msgNum: '',
      //几个人
      referAll: {},
      //提交给父级的所有人--对象
      userLists: []
    };
  },
  props: {
    itemUser: Object
  },
  watch: {
    itemUser: {
      //深度监听父组件的list
      handler: function handler(newVal) {
        if (newVal.cc.length > 3) {
          var userArrary = _toConsumableArray(newVal.cc);

          var alist = userArrary.splice(0, 2);
          this.userLists = alist;
        } else {
          this.userLists = _toConsumableArray(newVal.cc);
        }

        if (this.userLists.length == 0) {
          Dialog({
            title: '提示',
            message: '未查询到相关审批人,请联系管理员',
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          });
        }

        this.moreLists = _toConsumableArray(newVal.cc);
        this.handelShowUserList(newVal.cc);
        this.countUser();
      },
      deep: true,
      immediate: true
    },
    msgNum: {
      handler: function handler(newVal, oldVal) {
        if (oldVal > 0) {
          this.$emit('gechaosongrenshu', newVal);
        }

        if (newVal < 10) {
          this.addFlag = true;
        } else if (newVal >= 10) {
          this.addFlag = false;
        }
      },
      deep: true
    }
  },
  methods: {
    // 删减人员的增减的效果---统一的  lwt  07-03
    countUser: function countUser() {
      var lists = [];

      if (this.moreLists.length == 0) {
        this.msgNum = this.userLists.length;
        lists = _toConsumableArray(this.userLists);
      } else {
        this.msgNum = this.moreLists.length;
        lists = _toConsumableArray(this.moreLists);
      }

      var ulists = [];
      lists.forEach(function (item) {
        ulists.push(item.userSerial);
      });
      this.referAll = {
        level: 1,
        way: this.itemUser.copyWay,
        userSerial: ulists,
        cc: '1' //我自己要加的

      };
      this.$emit('getSubUserObj', this.referAll);
    },
    // 查看名字---lwt 07-03
    moreName: function moreName(name) {
      Dialog({
        message: name,
        width: '70%',
        closeOnPopstate: true
      });
    }
  },
  mounted: function mounted() {}
};