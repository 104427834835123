/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Popup from 'vux/src/components/popup';
import TransferDom from 'vux/src/directives/transfer-dom';
import { Dialog } from 'vant';
import approvalMixin from "@/mixins/approval/approvalNode";
export default {
  mixins: [approvalMixin],
  components: {
    Popup: Popup
  },
  directives: {
    TransferDom: TransferDom
  },
  data: function data() {
    return {
      wayName: ['', '会签', '或签', '依次审批'],
      moreLists: [],
      //更多的展示页面的
      addFlag: true,
      //加号的显示
      allLists: [],
      //可添加的人
      showAllBtn: false,
      //
      msgNum: '',
      //几个人
      referAll: {} //提交给父级的所有人--对象

    };
  },
  props: {
    itemUser: Object
  },
  watch: {
    itemUser: {
      //深度监听父组件的list
      handler: function handler(newVal) {
        this.allLists = _toConsumableArray(newVal.user);
        this.countUser();
      },
      deep: true,
      immediate: true
    },
    allLists: {
      //添加的列表
      handler: function handler(newVal) {
        var _this = this;

        this.$nextTick(function () {
          if (newVal.length == 0) {
            _this.addFlag = false;
            _this.showAllBtn = false;
          } else {
            _this.addFlag = true;
          }
        });
      },
      deep: true
    },
    moreLists: {
      //添加的列表
      handler: function handler(newVal) {
        this.handelShowUserList(newVal);
        this.countUser();
      },
      deep: true
    },
    msgNum: {
      handler: function handler(newVal) {
        this.$emit('getShenpiRenshu', newVal);

        if (newVal < 10) {
          this.addFlag = true;
        } else if (newVal >= 10) {
          this.addFlag = false;
        }
      },
      deep: true
    }
  },
  methods: {
    // --添加人员的点击事件--弹窗内的 lwt  07-02  修改--  07-17
    addUserClick: function addUserClick(user, i) {
      var addUser = this.allLists.splice(i, 1);
      this.moreLists.push(addUser[0]);
    },
    // ---删除人员的点击事件--展示列表的 lwt  07-02   修改--  07-17
    clearUserClick: function clearUserClick(i) {
      var _this2 = this;

      var clearUser = this.userLists.splice(i, 1);
      this.moreLists.some(function (sub, ix) {
        if (clearUser[0].userSerial == sub.userSerial) {
          _this2.moreLists.splice(ix, 1);

          return true;
        }
      });
      this.allLists.push(clearUser[0]);

      if (this.userLists.length == 0 && this.moreLists.length > 1) {
        this.$set(this.userLists, 0, this.moreLists[0]);
      }
    },
    // ---删除人员的点击事件--更多列表的 lwt  07-02 修改--  07-17
    moreClearClick: function moreClearClick(i) {
      var _this3 = this;

      var clearUser = this.moreLists.splice(i, 1);
      this.allLists.push(clearUser[0]);

      if (this.userLists.length == 2 && this.moreLists.length > 2) {
        this.userLists.splice(0, 1);
        this.$set(this.userLists, 0, this.moreLists[0]);
      } else if (this.moreLists.length == 2) {
        this.showMoreBtn = false;
        this.moreLists.some(function (item, i) {
          _this3.$set(_this3.userLists, i, _this3.moreLists[i]);
        });
      } else {
        this.$set(this.userLists, 0, this.moreLists[0]);
      }
    },
    // 删减人员的增减的效果---统一的  lwt  07-03
    countUser: function countUser() {
      // this.msgNum = this.moreLists.length
      var lists = [];

      if (this.moreLists.length == 0) {
        this.msgNum = this.userLists.length;
        lists = _toConsumableArray(this.userLists);
      } else {
        this.msgNum = this.moreLists.length;
        lists = _toConsumableArray(this.moreLists);
      }

      var ulists = [];
      lists.forEach(function (item) {
        ulists.push(item.userSerial);
      });
      this.referAll = {
        level: this.itemUser.level,
        way: this.itemUser.levelWay,
        userSerial: ulists,
        levelId: this.itemUser.levelId
      };
      this.$emit('getSubUserObj', this.referAll);
    },
    // 查看名字---lwt 07-03
    moreName: function moreName(name) {
      Dialog({
        message: name,
        width: '70%',
        closeOnPopstate: true
      });
    }
  }
};