/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Popup from 'vux/src/components/popup';
import TransferDom from 'vux/src/directives/transfer-dom';
import { Dialog } from 'vant';
import approvalMixin from "@/mixins/approval/approvalNode";
export default {
  mixins: [approvalMixin],
  components: {
    Popup: Popup
  },
  directives: {
    TransferDom: TransferDom
  },
  data: function data() {
    return {
      wayName: ['', '会签', '或签'],
      moreLists: [],
      //更多的展示页面的
      //  itemUserList:[],//全部的审批人
      msgNum: '',
      //几个人
      referAll: {} //提交给父级的所有人--对象

    };
  },
  props: {
    itemUser: Object
  },
  watch: {
    itemUser: {
      //深度监听父组件的list
      handler: function handler(newVal) {
        this.handelShowUserList(newVal.user);
        this.moreLists = _toConsumableArray(newVal.user); //更多的

        this.countUser();
      },
      deep: true,
      immediate: true
    },
    msgNum: {
      handler: function handler(newVal) {
        this.$emit('getShenpiRenshu', newVal);

        if (newVal < 10) {
          this.addFlag = true;
        } else if (newVal >= 10) {
          this.addFlag = false;
        }
      },
      deep: true
    }
  },
  methods: {
    // 删减人员的增减的效果---统一的  lwt  07-03
    countUser: function countUser() {
      var lists = [];

      if (this.moreLists.length == 0) {
        this.msgNum = this.userLists.length;
        lists = _toConsumableArray(this.userLists);
      } else {
        this.msgNum = this.moreLists.length;
        lists = _toConsumableArray(this.moreLists);
      }

      var ulists = [];
      lists.forEach(function (item) {
        ulists.push(item.userSerial);
      });
      this.referAll = {
        level: this.itemUser.level,
        way: this.itemUser.levelWay,
        userSerial: ulists,
        levelId: this.itemUser.levelId
      };
      this.$emit('getSubUserObj', this.referAll);
    },
    // 查看名字---lwt 07-03
    moreName: function moreName(name) {
      Dialog({
        message: name,
        width: '70%',
        closeOnPopstate: true
      });
    }
  },
  mounted: function mounted() {}
};