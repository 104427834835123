/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Previewer from 'vux/src/components/previewer';
import TransferDom from 'vux/src/directives/transfer-dom';
export default {
  directives: {
    TransferDom: TransferDom
  },
  components: {
    Previewer: Previewer
  },
  props: {
    limitNum: {
      //上传照片限制张数，默认 5 张(18/3/12定pc及手机端上传张数最多五张)
      type: Number,
      default: 5
    },
    icon: {
      type: String,
      default: 'icon-jiahao1'
    },
    title: {
      type: String,
      default: '上传照片'
    },
    sourceType: {
      type: Array,
      default: function _default() {
        return ['album', 'camera'];
      }
    },
    imgList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      env: null,
      images: {
        localId: [],
        //已选择的本地图片
        serverId: [] //微信服务器返回的mediaId

      },
      // imgList: [], //Base64图片数组
      imgPath: [],
      //图片路径数组
      saveImages: {
        previewImageList: [],
        //预览大图数组,
        previewThumbList: [],
        //预览缩略图数组
        saveImageList: [] //预览大图数组//保存图片

      },
      uploadPath: '',
      //图片上传的路径：为空时，默认下载到当前项目的/mcupload路径下
      options: {
        getThumbBoundsFn: function getThumbBoundsFn(index) {
          var thumbnail = document.querySelectorAll('.previewer-demo-img')[index];
          var pageYScroll = window.scrollY || document.documentElement.scrollTop;
          var rect = thumbnail.getBoundingClientRect();
          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }
      }
    };
  },
  methods: {
    chooseImage: function chooseImage() {
      var _this = this;

      this.$utils.Tools.getEnv(function (res) {
        _this.env = res;

        if (res === 0 || res === 1) {
          setTimeout(function () {
            _this.wxChooseImage();
          }, 100);
        } else {
          setTimeout(function () {
            _this.dtChooseImage();
          }, 100);
        }
      });
    },
    dtChooseImage: function dtChooseImage() {
      var _this2 = this;

      this.limitNum = this.limitNum - this.imgList.length;
      this.$utils.DingTalk.uploadImage(function (res) {
        if (_this2.$utils.Tools.isArray(res)) {
          var _iterator = _createForOfIteratorHelper(res),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;

              _this2.imgList.push({
                src: item,
                msrc: item,
                serverId: item
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      }, this.limitNum);
    },

    /**
     * 选择图片
     */
    wxChooseImage: function wxChooseImage() {
      var _this3 = this;

      this.limitNum = this.limitNum - this.imgList.length;
      var i = 0;
      this.$utils.WeiXin.wxChooseImage(function (res) {
        if (res.length > 0) {
          _this3.uploadImage(res, i);
        }
      }, this.limitNum, this.sourceType);
    },
    uploadImage: function uploadImage(res, i) {
      var _this4 = this;

      this.$utils.WeiXin.wxUploadImage(function (serverId) {
        _this4.$api.Sys.getWxImageUrl(serverId, _this4.$params.mode).then(function (ress) {
          _this4.imgList.push({
            src: ress.data,
            msrc: res[i],
            serverId: serverId
          });

          i++;

          if (i < res.length) {
            _this4.uploadImage(res, i);
          }
        }).catch();
      }, res[i]);
    },
    //预览图片
    logIndexChange: function logIndexChange() {},
    show: function show(index) {
      this.$refs.previewer.show(index);
    },
    //删除图片，数据库里没删除
    deleteImage: function deleteImage(index) {
      this.imgList.splice(index, 1);
      this.$emit('deleteImage', index);
    }
  },
  created: function created() {
    var _this5 = this;

    this.$emit('saveImages', this.imgList);
    var localUrl = window.location.href.split('#')[0];
    this.$api.Sys.getConfig(localUrl, this.$params.mode).then(function (response) {
      var code = response.code,
          msg = response.msg,
          data = response.data;

      if (+code !== _this5.$code.success) {
        _this5.$utils.Tools.toastShow(msg);
      } else {
        _this5.$utils.WeiXin.wxConfig(data);
      }
    }).catch(function () {
      _this5.$utils.Tools.httpError();
    });
  }
};